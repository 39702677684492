import { createStore } from 'vuex'

export default createStore({
  state: {
    tpList:[
      {
        title: '商家专场直播', tip: '本地生活GMV数千万/月', content: [
          { img: 'pc/mode/tp/1/sjzczb1_img.jpg', desc: '餐饮美食',active:true },
          { img: 'pc/mode/tp/1/sjzczb2_img.png', desc: '酒店行业',active:false },
          { img: 'pc/mode/tp/1/sjzczb3_img.png', desc: '文旅景区',active:false },
          { img: 'pc/mode/tp/1/sjzczb4_img.png', desc: '休闲娱乐',active:false },
          { img: 'pc/mode/tp/1/sjzczb5_img.png', desc: '时尚丽人' ,active:false},
        ]
      },
      {
        title: '达人探店带货', tip: '合作达人1000+', content: [
          { video: 'pc/mode/tp/2/1.mp4',poster:'m_phone/videoBg/vdieo1_1.jpg', desc: '餐饮美食',active:true,playStatus:false },
          { video: 'pc/mode/tp/2/2.mp4',poster:'m_phone/videoBg/vdieo1_2.jpg', desc: '酒店行业',active:false,playStatus:false  },
          { video: 'pc/mode/tp/2/3.mp4',poster:'m_phone/videoBg/vdieo1_3.jpg', desc: '文旅景区',active:false,playStatus:false  },
          { video: 'pc/mode/tp/2/4.mp4',poster:'m_phone/videoBg/vdieo1_4.jpg', desc: '休闲娱乐',active:false,playStatus:false  },
          { video: 'pc/mode/tp/2/5.mp4',poster:'m_phone/videoBg/vdieo1_5.jpg', desc: '时尚丽人',active:false,playStatus:false  },
        ]
      },
      {
        title: '短视频内容营销', tip: '获赞量5000万+', content: [
          { video: 'pc/mode/tp/3/1.mp4',poster:'m_phone/videoBg/vdieo2_1.jpg', desc: '餐饮美食',active:true, playStatus:false  },
          { video: 'pc/mode/tp/3/2.mp4',poster:'m_phone/videoBg/vdieo2_2.jpg', desc: '酒店行业',active:false, playStatus:false  },
          { video: 'pc/mode/tp/3/3.mp4',poster:'m_phone/videoBg/vdieo2_3.jpg', desc: '文旅景区',active:false, playStatus:false  },
          { video: 'pc/mode/tp/3/4.mp4',poster:'m_phone/videoBg/vdieo2_4.jpg', desc: '休闲娱乐',active:false, playStatus:false  },
          { video: 'pc/mode/tp/3/5.mp4',poster:'m_phone/videoBg/vdieo2_5.jpg', desc: '时尚丽人',active:false, playStatus:false  },
        ]
      },
      {
        title: '商家号代运营', tip: '合作商家1000+', content: [
          { img: 'pc/mode/tp/4/sjhdyy1_img.jpg', desc: '餐饮美食',active:true },
          { img: 'pc/mode/tp/4/sjhdyy2_img.jpg', desc: '餐饮美食',active:false },
          // { img: 'pc/mode/tp/4/sjhdyy1_img.png', desc: '文旅景区',active:false },
          // { img: 'pc/mode/tp/4/sjhdyy1_img.png', desc: '休闲娱乐',active:false },
          // { img: 'pc/mode/tp/4/sjhdyy1_img.png', desc: '时尚丽人',active:false },
        ]
      }
    ]
  },
  mutations: {
    SET_STATUS: (state: any, value: any) => {
      console.log("123123123")
      state.tpList.map((item:any,index:Number) =>{
        if(index === 1 || index ===2){
          item.content.find((val:any) =>{
            if(val.video === value.video){
              val.playStatus = value.status
            }else{
              val.playStatus = false
            }
          })
        }
      })
      console.log(state.tpList,"bbbb")
    },
  },
  actions: {
  },
  getters:{
      getTpList: (state: { [key: string]: any }): any => state.tpList,
  }
})
