export const contentList = [
  {
    title: '比夫电竞',
    name: '全场景电竞综合服务平台',
    desc: '以200+头部品牌客户的服务经验打造KA级全场景电竞数字互动体验SaaS产品；在网罗、独创独特互动体验玩法的同时带来多元化营销工具，即可自由组合也可独立使用；并以丰富游戏化皮肤，轻松实现与游戏产品的完美契合；快速创造品牌与用户之间的全场景游戏化互动内容；通过场景创新与数据驱动，提升用户体验管理与运营能力；助力品牌实现拉新、促活、转化、交易的指数增长；深度洞察用户行为，帮助品牌更好地经营决策。',
    icon1: 'icon-bfwh_black_icon',
    icon2: 'icon-more_icon',
    picIcon: 'icon/bfdj2_icon@2x.png'
  },
  {
    title: '汇智互娱智能',
    name: '新媒体数字营销服务商',
    desc: '汇智互娱智能以本地生活服务、5G技术、新媒体数字营销为核心，通过本地生活品牌直播营销，本地生活品牌短视频运营，本地生活团购达人，本地生活品牌私域营销，大数据分析运营为客户赋能；帮助客户搭建数字营销系统，提升线上营销效率，覆盖全平台，掌握全时段流量，助力客户在生产服务中创造更多收益。',
    icon1: 'icon-hzzn_black_icon',
    icon2: 'icon-hzzn_white_icon',
    picIcon: 'icon/hzzn2_icon@2x.png'
  },
  {
    title: '比夫文化',
    name: '全球供应链综合服务商',
    desc: '主营全球品牌供应链、美妆达人供应链、电商直播供应链、线下集采团购等业务；累计已向千余名主播、企事业单位、电商机构提供产品供应链服务，同时开展TikTok国际业务；创立至今销售额已过千万，为海内外消费者提供优质货源和无忧售后服务。',

    icon1: 'icon-bfdj_black_icon',
    icon2: 'icon-bfdj_white_icon',
    picIcon: 'icon/bfwh2_icon@2x.png'
  }
]

export const buffSportsList = [
  {
    id: 'b',
    title: '本地生活数字智能',
    itemList: [
      {
        id: 's',
        name: '720°全场景营销服务',
        desc: '通过线上和线下结合的全场景营销体系，让流量导入更迅速，效果更直接'
      },
      {
        id: 'p',
        name: '电竞直播营销带货',
        desc: '电竞+直播带货，专业的电竞主播帮您开辟销售新渠道'
      },
      {
        id: 'o',
        name: '专业技术定制开发',
        desc: '围绕品牌方需求，提供专业的定制化技术开发服务'
      },
    ]
  },
  {
    id: 'u',
    title: '直播供应链运营',
    itemList: [
      {
        id: 'r',
        name: '品牌IP赛事打造',
        desc: '为品牌方建立专属于自己IP赛事，提供一站式营销服务'
      },
      {
        id: 't',
        name: '品牌赛事曝光赞助',
        desc: '为品牌方和官方IP赛事打通，提供全面宣传曝光'
      },
      {
        id: 'l',
        name: '赛事IP支撑服务',
        desc: 'CEST官方指定赛事承办方<br/>英雄联盟手游官方指定赛事承办方'
      },
    ]
  },
  {
    id: 'f',
    title: '游戏电竞直播',
    itemList: [
      {
        id: 'i',
        name: '电竞游戏用户服务',
        desc: '专业运营师团队',
        desc2: '精细化赛事服务',
        desc3: '电竞内容咨询沟通'
      },
      {
        id: 'v',
        name: '电竞+数字经济',
        desc: '电竞+云电竞',
        desc2: '电竞+5G应用',
        desc3: '电竞+AI数字人工GS服务'
      },
      {
        id: 't',
        name: '用户社交娱乐',
        desc: '电竞娱乐直播',
        desc2: '兴趣社群交流',
        desc3: '好友一键开黑'
      },
    ]
  }
]

export const buffCultureList = [
  {
    title: '品牌对接',
    desc: '品牌产品提报供应链中心'
  },
  {
    title: '筛选供应链',
    desc: '筛选合适的品牌供应链'
  },
  {
    title: '合作授权',
    desc: '签订合作授权协议'
  },
  {
    title: '上架分销',
    desc: '品牌产品上架店铺，线上渠道带货分销'
  }
]

export const developList = [
  {
    time: '2017',
    title: '成立',
    descs: ['南京汇智互娱网络科技有限公司成立'],
    tips: []
  },
  {
    time: '2018',
    title: '创新',
    descs: ['与腾讯、网易、完美世界等大型游戏厂商合作', '江苏省电子竞技运动协会理事单位'],
    tips: ['2018年度中网协行业榜样企业']
  },
  {
    time: '2019',
    title: '突破',
    descs: ['成为中国电子竞技娱乐大赛连续5年独家承办方', '南京市“专精特新”中小企业', '江苏省民营技术企业'],
    tips: ['江苏省互联网企业50强', '国家高新技术企业']
  },
  {
    time: '2020',
    title: '赋能',
    descs: ['电竞赛事合作覆盖突破全国150座城市', 'Intel品牌赛事最佳合作伙伴', '年度电竞赛事办赛量超3000场', '2020年江苏省规划布局内重点软件企业'],
    tips: ['江苏省规划布局内重点软件企业', '南京市创新型领军企业']
  },
  {
    time: '2021',
    title: '聚变',
    descs: ['电竞赛事合作覆盖突破全国150座城市'],
    tips: ['腾讯年度最佳授权电竞赛事', '南京市第九届文化产业“金梧桐奖”创新成长企业', '省“最具成长性高科技企业”百强', '成为抖音生活服务-服务商', '商家服务月度销售额超过3000万+', '江苏省重点饮件企业']
  },
  {
    time: '2022',
    title: '拓展',
    descs: ['成为抖音生活服务机构-MCN'],
    tips: ['华东文旅数字化运营服务商头部企业', '南京市优秀场景示范项目', '数字中国电竞+赛道全国一等奖', '游戏电竞赛事年度办赛量3000场+', '业务已覆盖全国城市150座+', '为品牌商家提供数字化营销服务模式,累计合作10000家+', '提供品牌商家营销合作达人20000位+', '数字化服务团队200人+', '公司实现累计营收近10亿元', '本地生活GMV数千万']
  }
]

export const developInfo = {
  time: '2017',
  title: '成立',
  descs: ['南京汇智互娱网络科技有限公司成立'],
  tips: []
}

export const mcnList = [
  { img: 'm_phone/mc/shfw1_img@3x.png', title: '专业的运营团队', desc1: '商家前中后期三个阶段，专属平台运营客服一对一、一站式服务对接', desc2: '', desc3: '' },
  { img: 'm_phone/mc/shfw2_img@3x.png', title: '云计算中心', desc1: '商家数据分析，消费者画像一一锁定', desc2: '', desc3: '' },
  { img: 'm_phone/mc/shfw3_img@3x.png', title: '数据运营中台', desc1: '销售数据，产品销量数据分析，智能筛选', desc2: '', desc3: '' },
  { img: 'm_phone/mc/shfw4_img@3x.png', title: '数字化协同', desc1: '任务发布开始到执行落地完成，线上流转', desc2: '', desc3: '' },
  { img: 'm_phone/mc/shfw5_img@3x.png', title: '流媒体中心', desc1: '千人千策，实时数据多屏监测，降本增效', desc2: '', desc3: '' }
]

export const mcnSonList = [
  {img: 'm_phone/mc/yewu_mcn_4_img@3x.png', desc1: '直播带货&品牌代运营', desc2: '·AI短视频制作、数字直播、云计算投流三频共振',desc3: '·赋能传统文旅数字营销创意、达人矩阵带货服务', desc4: '·品效合一，助力品牌声量和产品销量'},
  {img: 'm_phone/mc/yewu_mcn_5_img@3x.png', desc1: '直播带货&品牌代运营', desc2: '·AI短视频制作、数字直播、云计算投流三频共振',desc3: '·赋能传统文旅数字营销创意、达人矩阵带货服务', desc4: '·品效合一，助力品牌声量和产品销量'}
]

export const cloudList = [
  { img: 'm_phone/cloud/ydr1_img@3x.png', title: 'AI视频中心', desc1: 'AI技术赋能短视频内容生产千倍提速；多短视频平台营销任务一键分发；任务全流程管理+效果追踪，有效激励传播；多维度任务看板，精准管理全员营销绩效。', desc2: '', desc3: ''},
  { img: 'm_phone/cloud/ydr2_img@3x.png', title: '全员账号矩阵', desc1: '企业员工、员工亲友、全民经纪人；合作供应商及媒体等贡献传播账号；传播数据企业后台收回跟踪；全员短视频营销；高密度种草放大品牌曝光量。', desc2: '', desc3: '' },
  { img: 'm_phone/cloud/ydr3_img@3x.png', title: '智能任务系统', desc1: '视频任务一键领取、抖音、快手、短视频等多平台一键分发；多功能任务看板实现精准全员营销管理；更有团长任务全流程管理+效果追踪，有效激励传播。', desc2: '', desc3: '' },
  { img: 'm_phone/cloud/ydr4_img@3x.png', title: '多效营销组建', desc1: '全民激励任务、POI、团购等；多效营销组件，构建多样化的内容；经营模型，轻松获取并转化线索；助力销售订单，形成企业数字化经营沉淀。', desc2: '', desc3: '' }
]

export const cloudSonList = [
  {img: 'm_phone/cloud/ydr5_img@3x.png', desc1: '零售', desc2: '',desc3: '', desc4: ''},
  {img: 'm_phone/cloud/ydr6_img@3x.png', desc1: 'AI智能营销矩阵', desc2: '',desc3: '', desc4: ''}
]

export const supplyList = [
  { img: 'm_phone/supply/dsgyl1_img@3x.png', title: '供应链整合', desc1: '快速高效资源嫁接，整合产业生态圈闭环，提高企业效能，打造富有弹性、可持续发展的供应链', desc2: '', desc3: ''},
  { img: 'm_phone/supply/dsgyl2_img@3x.png', title: '供应链分发', desc1: '全渠道达人资源库，平台一键任务下发。', desc2: '', desc3: '' },
  { img: 'm_phone/supply/dsgyl3_img@3x.png', title: '智能任务系统', desc1: '为不同行业企业/商家提供供应链金融服务平台，实现相关金融服务和管理；为银行、金融公司等提供供应链金融平台，以SaaS模式对外提供金融服务。', desc2: '', desc3: '' }
]

export const supplySonList = [
  {img: 'm_phone/supply/dsgyl4_img@3x.png', desc1: '美妆', desc2: '',desc3: '', desc4: ''},
  {img: 'm_phone/supply/dsgyl5_img@3x.png', desc1: '品牌食品', desc2: '',desc3: '', desc4: ''}
]

export const electronicList = [
  { img: 'm_phone/electronic/yxdj1_img@3x.png', title: '赛事营销策划', desc1: '品牌+电竞营销服务', desc2: '电竞游戏生态赋能', desc3: '电竞游戏用户服务'},
  { img: 'm_phone/electronic/yxdj2_img@3x.png', title: 'IP赛事运营', desc1: '电子竞技赛事系统', desc2: '千家品牌IP合作', desc3: 'CEST自有IP' },
  { img: 'm_phone/electronic/yxdj3_img@3x.png', title: '游戏营销推广', desc1: '多平台媒体整合资源', desc2: '自有高效的营销工具', desc3: '用户社交娱乐平台' },
  { img: 'm_phone/electronic/yxdj4_img@3x.png', title: '游戏联运分发', desc1: '强大资源库', desc2: '电竞+云电竞', desc3: '电竞+5G应用', desc4: '电竞+AI数字人GS服务' }
]

export const electronicSonList = [
  {img: 'm_phone/electronic/yxdj5_img@3x.png', desc1: '线上互动+线下互动精准覆盖；优质服务线下娱乐实体店用户；垂直游戏推广渠道；专业游戏SDK接入技术平台；通过比夫APP线上线下活动营销；展现推广的手游产品精准推送', desc2: '',desc3: '', desc4: ''},
  {img: 'm_phone/electronic/yxdj6_img@3x.png', desc1: '热门游戏IP赛事授权；为商户导流增客；引入一线游戏厂商当下最火的；游戏IP大型赛事活动；优选汇智+合作商户落地；并获得官方授权、媒体报道；赞助权益等支持', desc2: '',desc3: '', desc4: ''}
]

export const metaverseList = [
  { img: 'm_phone/metaverse/cyyyz1_img@3x.png', title: 'AI虚拟数字人IP制作', desc1: '品牌+为客户量身定制虚拟偶像全配套服务打造属于自己的超写实IP，利用成熟制作流程循序渐进打造出多个不同造型及特点的虚拟偶像，为客户实现虚拟IP商业化运作。', desc2: '', desc3: ''},
  { img: 'm_phone/metaverse/cyyyz2_img@3x.png', title: '虚拟数字人直播', desc1: '汇智互娱虚拟人技术产品，广泛服务于政务、展馆、银行、餐饮、商超、零售的在线直播场景运用，有机融合视觉、语音、手势、情感和虚拟形象，提供更自然的交互机制。', desc2: '', desc3: '' },
  { img: 'm_phone/metaverse/cyyyz3_img@3x.png', title: '虚拟场景直播', desc1: '为客户量身制定虚拟场景构建，现场环境调整，录制四个阶段完成AR直播活动。主要应用在各种类型的节目录制中其中包括新闻，访谈，体育，文艺等节目。', desc2: '', desc3: '' }
]

export const metaverseSonList = [
  {img: 'm_phone/metaverse/cyyyz4_img@3x.png', desc1: '线上互动+打造多个不同造型及特点的虚拟偶像，实现虚拟IP商业化运作。', desc2: '',desc3: '', desc4: ''},
  {img: 'm_phone/metaverse/cyyyz5_img@3x.png', desc1: '有机融合视觉、语音、手势、情感和虚拟形象，提供更自然的交互机制。', desc2: '',desc3: '', desc4: ''},
  {img: 'm_phone/metaverse/cyyyz6_img@3x.png', desc1: '量身制定虚拟场景构建，现场环境调整，录制完成直播活动。', desc2: '',desc3: '', desc4: ''}
]

export const tpList = [
  {
    title: '商家专场直播', tip: '本地生活GMV数千万/月', content: [
      { img: 'pc/mode/tp/1/sjzczb1_img.jpg', desc: '餐饮美食',active:true },
      { img: 'pc/mode/tp/1/sjzczb2_img.png', desc: '酒店行业',active:false },
      { img: 'pc/mode/tp/1/sjzczb3_img.png', desc: '文旅景区',active:false },
      { img: 'pc/mode/tp/1/sjzczb4_img.png', desc: '休闲娱乐',active:false },
      { img: 'pc/mode/tp/1/sjzczb5_img.png', desc: '时尚丽人' ,active:false},
    ]
  },
  {
    title: '达人探店带货', tip: '合作达人1000+', content: [
      { video: 'pc/mode/tp/2/1.mp4',poster:'m_phone/videoBg/vdieo1_1.jpg', desc: '餐饮美食',active:true },
      { video: 'pc/mode/tp/2/2.mp4',poster:'m_phone/videoBg/vdieo1_2.jpg', desc: '酒店行业',active:false },
      { video: 'pc/mode/tp/2/3.mp4',poster:'m_phone/videoBg/vdieo1_3.jpg', desc: '文旅景区',active:false },
      { video: 'pc/mode/tp/2/4.mp4',poster:'m_phone/videoBg/vdieo1_4.jpg', desc: '休闲娱乐',active:false },
      { video: 'pc/mode/tp/2/5.mp4',poster:'m_phone/videoBg/vdieo1_5.jpg', desc: '时尚丽人',active:false },
    ]
  },
  {
    title: '短视频内容营销', tip: '获赞量5000万+', content: [
      { video: 'pc/mode/tp/3/1.mp4',poster:'m_phone/videoBg/vdieo2_1.jpg', desc: '餐饮美食',active:true },
      { video: 'pc/mode/tp/3/2.mp4',poster:'m_phone/videoBg/vdieo2_2.jpg', desc: '酒店行业',active:false },
      { video: 'pc/mode/tp/3/3.mp4',poster:'m_phone/videoBg/vdieo2_3.jpg', desc: '文旅景区',active:false },
      { video: 'pc/mode/tp/3/4.mp4',poster:'m_phone/videoBg/vdieo2_4.jpg', desc: '休闲娱乐',active:false },
      { video: 'pc/mode/tp/3/5.mp4',poster:'m_phone/videoBg/vdieo2_5.jpg', desc: '时尚丽人',active:false },
    ]
  },
  {
    title: '商家号代运营', tip: '合作商家1000+', content: [
      { img: 'pc/mode/tp/4/sjhdyy1_img.jpg', desc: '餐饮美食',active:true },
      { img: 'pc/mode/tp/4/sjhdyy2_img.jpg', desc: '餐饮美食',active:false },
      // { img: 'pc/mode/tp/4/sjhdyy1_img.png', desc: '文旅景区',active:false },
      // { img: 'pc/mode/tp/4/sjhdyy1_img.png', desc: '休闲娱乐',active:false },
      // { img: 'pc/mode/tp/4/sjhdyy1_img.png', desc: '时尚丽人',active:false },
    ]
  }
]

export const gameList = [
  {title: '赛事营销策划', tip: '累计赛事量10000+', img: 'm_phone/yewu_game_1_img@2x.png'},
  {title: 'IP赛事运营', tip: '直接参赛人数100W+', img: 'm_phone/yewu_game_2_img@2x.png'},
  {title: '游戏营销推广', tip: '覆盖用户数10000W+', img: 'm_phone/yewu_game_3_img@2x.png'},
  {title: '游戏联运分发', tip: '电竞运营师500+ </br>电竞合作主播1000+', img: 'm_phone/yewu_game_4_img@2x.png'}
]

export const digitList = [
  {img: 'm_phone/yewu_ai_1_img@2x.png', title: 'AI虚拟数字人IP制作'},
  {img: 'm_phone/yewu_ai_2_img@2x.png', title: '虚拟数字人直播'},
  {img: 'm_phone/yewu_ai_3_img@2x.png', title: '虚拟场景展会直播'}
]