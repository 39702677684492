import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/fonts/iconfont.css'
// import './assets/fonts/impact.css'
import filter from './util/filter'
// import 'videojs-contrib-hls/dist/videojs-contrib-hls'
// import VideoPlayer from 'vue-video-player'
// Vue.use(VideoPlayer);
const app = createApp(App)
app.config.globalProperties.$filters = filter

if (process.env.NODE_ENV !== 'development') {
    var targetProtocol = "https:"
    if (window.location.protocol != targetProtocol) {
        window.location.href =targetProtocol + window.location.href.substring(window.location.protocol.length);
    }
  }
app.use(store).use(router).mount('#app')
