import {request, request2} from '@http/request'
import { codeBaseUrl } from '@api/baseUrl'
import { TableListBody } from '~/index.t'

// // 查询商户信息
// export function merchantInfo (tenantId: string) {
//   return request({
//     url: `${codeBaseUrl}/local/store/get/by/${tenantId}`,
//     method: 'get'
//   })
// }

// 筛选列表
export function merchantFilterList () {
  return request({
    url: `${codeBaseUrl}/local/store/get/cache`,
    method: 'post'
  })
}
// 案例列表
export function merchantLiveList (data: any) {
  return request({
    url: `${codeBaseUrl}/case/library/official/list`,
    method: 'post',
    data
  })
}
// 首图列表
export function merchantLiveTopList (data: any) {
  return request({
    url: `${codeBaseUrl}/case/library/top/list`,
    method: 'post',
    data
  })
}
// 详情
export function localLifeStoreDetail (id: string) {
  return request({
    url: `${codeBaseUrl}/case/library/get/${id}`,
    method: 'post'
  })
}

// 我要合作
export function localLifeCooperation (body: any) {
  return request({
    url: `${codeBaseUrl}/local/store/purpose/insert`,
    method: 'post',
    body
  })
}

// 跳转小程序接口
export function getSchmaUrl(body: any) {
  return request2({
    url: `/api/marketing/dy/base/wx/openlink`,
    method: 'post',
	  body
  })
}