import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-e2f2b46a"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "text-more-content"
};
var _hoisted_2 = {
  class: "detailed-introduce"
};
var _hoisted_3 = {
  class: "intro-content",
  ref: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [$setup.introduce ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    class: "merchant-desc",
    ref: "desc"
  }, _toDisplayString($setup.introduce), 513)) : _createCommentVNode("", true), _ctx.showExchangeButton ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: "unfold",
    onClick: _cache[0] || (_cache[0] = function () {
      return $setup.clickTotal && $setup.clickTotal.apply($setup, arguments);
    })
  }, _toDisplayString(_ctx.showTotal ? '收起' : '展开'), 1)) : _createCommentVNode("", true)], 512)])]);
}