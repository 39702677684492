import { NavigationGuardWithThis, NavigationHookAfter } from 'vue-router'
import store from '@/store/index'

export const beforeEach: NavigationGuardWithThis<undefined> = (to, form) => {

}

export const afterEach: NavigationHookAfter = (to) => {
  document.documentElement.scrollTop = 0
  document.body.scrollTop = 0
}
