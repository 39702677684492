import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { beforeEach, afterEach } from '@/router/guard'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/public/:active',
    name: 'metaverse',
    component: Home
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(beforeEach)
router.afterEach(afterEach)

export default router
